<template>

<div class="page-wrapper">

    <div class="page-header">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">Timesheet</li>
        </ol>
    </div>

    <b-tabs card>
        <b-tab :title="$store.getters.username">
            <b-card-group deck>
                <Calendar></Calendar>
            </b-card-group>
        </b-tab>
        <b-tab title="All Users" v-if="$store.getters.userRole == 'admin'">
            <b-card no-body>
                <b-tabs pills card vertical v-model="currentUser" lazy>
                    <b-tab v-for="(user, index) in activeUsers"
                        :key="user.id"
                        :title="user.username"
                        :active="index == currentUser"
                    >
                        <Calendar :user="user"></Calendar>
                    </b-tab>
                </b-tabs>
            </b-card>
        </b-tab>
    </b-tabs>
    
</div>

</template>
<script>

import { getAllUsers } from '@/api/users.api'
import Calendar from '@/components/Calendar'
import crudMixin from '@/api/crud'

export default {
    mixins: [crudMixin],
  name: 'Timesheet',
  data () {
    return {
      users: [],
      currentUser: 0
    }
  },
  components: {
     Calendar
  },
  computed: {
    isNew: function() {
        return ! ('id' in this.form)
    },
    activeUsers: function(){
        return this.users.filter(function(u) {
            return u.deleted == false
        })
    }

  },
  mounted() {
    if(this.$store.getters.userRole == 'admin') {
        this.getUsers();
    }
  },
  methods: {
      getUsers() {
        this.call(
            getAllUsers(),
            (res) => {
                this.users = res.data.users
            }
        )
    }
  }
}
</script>
<style scoped>
/deep/ div.header{
 border: 1px solid #000;
}
</style>

