<template>
    <div id="calendarMain" >
        <vue-cal
        :time="false"
        active-view="month" 
        :disable-views="['years', 'week', 'day', 'year']"
        hide-view-selector
        :events="events"
        events-on-month-view="short"
        startWeekOnSunday
        @cell-dblclick="showEventModal($event)"
        @view-change="getHistory($event)"
        >   
            <template #title="{title, view}">
                <div class="customTitle">
                    <span>{{ view.startDate.toLocaleString('default', { month: 'long' })}} {{ view.startDate.getFullYear()}}</span>
                    <span>Total Hours: {{ computedTotalHours }}</span>
                </div>
            </template>
            <template #event="{ event }" style="position:absolute">
                <div 
                class="vuecal__event-title p-1" 
                v-html="`${event.hours} ${event.hours != '1.00' ? 'hrs' : 'hr'}`" 
                :id="'popover-target-'+event.id"
                @click="log(event)"
                />
                <b-popover :target="'popover-target-'+event.id" triggers="hover" placement="right" >
                    <template #title>{{event.title}}</template>
                        <div>
                            <p>{{event.content}} ({{event.hours}} hours)</p>
                        </div>
                        <div>
                            <b-button variant="primary" @click="onEdit(event)" v-if="!event.invoiced" >
                                <b-icon icon="pencil" />
                            </b-button>
                            <b-button variant="primary" @click="onTemplate(event)">
                                <b-icon icon="clipboard" />
                            </b-button>
                            <b-button variant="primary" @click="onDelete(event)" v-if="!event.invoiced">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </div>
                </b-popover>
            </template>
        </vue-cal>
        <b-modal 
        ref="newEvent" 
        title="Time Selector"
        ok-variant="success"
        ok-title="Save"
        cancel-variant="danger"
        @ok.prevent="onSave">
            <span v-if="error" class="error">{{ error }}</span>
            <inline-form-row label="User" _type="b-form-select" :options="computedUsers" v-model="form.user_id" :errors="errors['user']" v-if="$store.getters.userRole == 'admin' && user" icon-field="pencil-fill" :disabled="isUseredit"></inline-form-row>
            <b-form-row v-if="isNew">
                <b-col class="col-sm-3"><label class="col-form-label">Date</label></b-col>
                <b-col class="col-sm-6">
                    <date-range-picker 
                    v-model="dateRange"
                    :errors="errors['date']"
                    >
                        <template>
                            {{ dateRange.startDate | date }} -{{ dateRange.endDate | date }}
                        </template>
                    </date-range-picker>
                </b-col>
            </b-form-row>
            <inline-form-row label="Date" _type="b-form-datepicker" v-model="form.date" :errors="errors['date']" v-else/>
            <inline-form-row label="Client" _type="b-form-select" :options="clients" v-model="selected_client" :errors="errors['consultant_role']"/>
            <inline-form-row label="Role" _type="b-form-select" :options="projects[selected_client]" v-model="form.consultant_role.id" :errors="errors['consultant_role']" v-if="selected_client"/>
            <inline-form-row label="Hours" type="text" v-model="form.time" :errors="errors['time']"/>
            <inline-form-row label="Comment" _type="b-form-textarea" v-model="form.comments" :errors="errors['comments']"/>
        </b-modal> 

    </div>
</template>

<script>

import { getTimesheetInvoiceMapping, getAllUsers } from '@/api/users.api'
import { getConsultingTimes, getOutstandingTimes, getConsultingRoles, addConsultingTime, updateConsultingTime, deleteConsultingTime } from '@/api/consulting.api'
import InlineFormRow from '@/components/InlineFormRow'
import crudMixin from '@/api/crud'
import confirmationModal from '@/api/confirmation'
import DateRangePicker from "vue2-daterange-picker"


export default {
    mixins: [crudMixin, confirmationModal],
    props: {
        user: Object
    },
    filters: {
        date(date) {
        return new Intl.DateTimeFormat("en-US").format(date);
        },
    },
    data() {
        const startDate = new Date();
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + 3);
        return {
            timeView: new Date(),
            items: [],
            currentUser: null,
            users: [],
            mapping: {},
            selectedEvent: {},
            form: {consultant_role : {}},
            clients: [],
            selected_client: null,
            projects: {},
            outstandingTimes:[],
            dateRange: {
                startDate,
                endDate
            },
            isUseredit: true
        }
    },
    components: {
     InlineFormRow,
     DateRangePicker
    },
    computed:{
        events(){
            this.selectedEvent
            return this.invoiced_items.map((item) => (
                {   
                    id: item.id,
                    start: item.date, 
                    end: item.date, 
                    title: `${item.consultant_role.project.client.company} / ${item.consultant_role.project.name}`,
                    content: item.comments == null ? '' : item.comments,
                    hours: item.time,
                    invoiced: item.invoiced,
                    consultant_role: item.consultant_role,
                    user_id: item.user_id
                }
                ))
        },
        isNew: function() {
            return ! ('id' in this.form)
        },
        computedUsers(){
            return this.users.map((user) => (
                {
                    value: user.id, 
                    text: user.username
                }
                ))
        },
        invoiced_items: function() {
            return this.items.map(item => {
                item.invoiced = true
                this.outstandingTimes.every(time => {
                    if (item.id == time.id) {
                        item.invoiced = false
                        // This in conjunction with every breaks the loop
                        return false
                    }
                    return true
                })
                return item
            })
        },
        computedTotalHours: function(){
            return this.items.filter(
                item => new Date(item.date).getUTCMonth() == this.timeView.getUTCMonth()
            ).reduce(
                (total, item) => total + parseFloat(item.time), 0
            )
        }
    },
    mounted() {
        if(this.user) {
            this.currentUser = this.user
            this.call(
                getTimesheetInvoiceMapping(this.currentUser.id),
                (response) => {
                    response.data.mapping.forEach(mapping => {
                        this.$set(this.mapping, mapping[0], mapping)
                    })
                }
            )
            this.form.user_id = this.user.id
        }
        if(this.$store.getters.userRole == 'admin') {
            this.getUsers();
        }
        this.getJobs()
        this.getHistory()
        this.$root.$on('editUser', () => {
            this.isUseredit = false
        })
    },
    methods: {
        getUsers() {
            this.call(
                getAllUsers(),
                (res) => {
                    this.users = res.data.users
                }
            )
        },
        getHistory: function(date) {
            if (!date){
                var firstDay = new Date(this.timeView.getFullYear(), this.timeView.getMonth(), 1);
                var lastDay = new Date(this.timeView.getFullYear(), this.timeView.getMonth() + 1, 0);
            }else{
                this.timeView = date.startDate
                firstDay = date.startDate
                lastDay = date.endDate
            }
            var params = new URLSearchParams([
                ['start', this.formatDate(firstDay)],
                ['end', this.formatDate(lastDay)]
            ])
            if(this.currentUser) {
                params.append("user_id", this.currentUser.id)
            } else {
                params.append("user_id", this.$store.getters.user_id)
            }
            this.call(
                getConsultingTimes(params),
                (res) => {
                    this.items.splice(0, this.items.length)
                    res.data.times.forEach(time => {
                        time.invoiced = true
                        this.items.push(time)
                    })
                }
            )
            this.call(
                getOutstandingTimes(params),
                (res) => {
                    this.outstandingTimes.splice(0, this.outstandingTimes.length)
                    res.data.times.forEach(time => {
                        this.outstandingTimes.push(time)
                    })
                }
            )
        },
        formatDate(date) {
            // Split date by month, day and year
            var d = new Date(date)
            var month = '' + (d.getMonth() + 1)
            var day = '' + d.getDate()
            var year = d.getFullYear()

            // Padding with "0"
            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            // Return date yyyy-mm-dd
            return [year, month, day].join('-');
        },
        showEventModal(day){
            this.onReset()
            this.dateRange = {
            startDate: day,
            endDate: day
            }
            if(this.user){
                this.form.user_id = this.user.id
            }
            this.$refs['newEvent'].show()
        },
        hideEventModal() {
        this.$refs['newEvent'].hide()
        },

        getJobs: function() {
        this.call(
            getConsultingRoles(),
            (res) => {
                this.clients.splice(0)
                res.data.roles.forEach(job => {
                    if (job.project && job.project.client && !job.project.deactivated) {
                        var val = {
                            'value': job.project.client.id,
                            'text': job.project.client.company
                        }
                        if (!this.clients.some(e => e.value === job.project.client.id)) {
                            this.clients.push(val)
                            if(job.project.client.id in this.projects) {
                                this.projects[job.project.client.id].splice(0)
                            } else {
                                this.projects[job.project.client.id] = []
                            }
                        }
                            this.projects[job.project.client.id].push({
                            'value': job.id,
                            'text': `${job.project.name} -> ${job.name}`
                        })
                    }
                })
                this.clients.sort(function(a, b){return a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1});
                }
            )
        },
        onReset: function() {
            this.form = {consultant_role : {}}
            this.selected_client = null
        },
        onSave: function() {
            const dates = this.getDates(this.dateRange.startDate, this.dateRange.endDate)
            console.log(dates);
            if(this.isNew){
                dates.forEach(date => {
                this.form.date = this.formatDate(date)
                var apiCall = addConsultingTime(this.form)
                this.saveObject(
                    apiCall,
                    () => {
                        this.key++;
                        this.hideEventModal()
                        this.getHistory()
                    })
            })
            }else{
                var apiCall = updateConsultingTime(this.form.id, this.form)
                this.saveObject(
                apiCall,
                () => {
                    this.key++;
                    this.hideEventModal()
                    this.getHistory()
                }
                )
            }
        },
        onEdit: function(event) {  
            this.form = {
                id: event.id,
                time: event.hours,
                comments: event.content,
                consultant_role: { id: event.consultant_role.id },
                date: this.formatDate(event.start)
            }
            this.dateRange = {
                startDate: event.start,
                endDate: event.end
            }
            if(this.currentUser){
                this.form.user_id = this.currentUser.id
            }else{
                this.form.user_id = this.user
            }
            this.selected_client = event.consultant_role.project.client.id
            this.$refs['newEvent'].show()
        },
        onTemplate: function(event) {
            this.form = {
                time: event.hours,
                comments: event.content,
                consultant_role: { 'id': event.consultant_role.id }
            }
            if(this.currentUser){
                this.form.user_id = this.currentUser.id
            }
            this.selected_client = event.consultant_role.project.client.id
            this.$refs['newEvent'].show()
        },
        onDelete: function(event){
            this.confirmModal().then((result) => {
                if (result){
                    this.deleteObject(
                        deleteConsultingTime(event.id),
                        () => {
                            this.key++;
                            this.onReset();
                            this.getHistory()
                        }
                    )
                }   
            })
        },
        getDates(startDate, endDate){
            const dates = []
            let currentDate = new Date(startDate)
            let lastDate = new Date(endDate)
            const addDays = function (days) {
                const date = new Date(this.valueOf())
                date.setDate(date.getDate() + days)
                return date
            }
            while (currentDate <= lastDate) {
                dates.push(currentDate)
                currentDate = addDays.call(currentDate, 1)
            }
            return dates
        },
        log(event){
            console.log(event);
        }
    }
}
</script>

<style>
.vuecal__title-bar{
    background-color: #4F2B76;
    color: white;
}
.vuecal__event {
    cursor: pointer;
    margin: 5px 0px;
    background-color: transparent;
}
.vuecal__event-title {
    font-size: 1.2em;
    font-weight: bold;
    background-color: #FE6C30;
    color: white;
    width: 40%;
    margin: 0px 10px;
    text-align: center !important;
}
.vuecal__event-time {
  display: inline-block;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.vuecal__event-content {
  font-style: italic;
}
.vuecal__arrow{
    color:white;
}
.vuecal__cell--selected, .vuecal__cell--today{
    background-color: #4f2b7623;
}
.month-view .vuecal__cell--has-events {
    overflow: hidden;
}
.month-view .vuecal__cell-content {
    position: relative;
    justify-content: flex-start;
    align-items: flex-end;
    font-size: 12px;
    overflow-y: auto;
}
.month-view .vuecal__cell-date {
    padding: 4px;
    height: 20px;
    flex-shrink: 0;
    box-sizing: border-box;
}
.month-view .vuecal__cell .more {
    position: absolute;
    z-index: 4;
    bottom: 2px;
    right: 2px;
    flex-shrink: 0;
    height: 11px;
    font-size: 10px;
    line-height: 1;
    border-radius: 14px;
    border: none;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    color: white;
    background-color: rgba(66, 163, 185, .8);
    outline: none;
    padding: 0 4px;
}
#calendarMain{
    height: 700px;
    width: 100%; 
    margin: auto;
}
.vue-daterange-picker{
    min-width: 14.2rem !important;
}
.customTitle{
    display: flex;
    flex-direction: column;
}
.vuecal__cell--out-of-scope{
    visibility: hidden;
}

.popover{
    position:absolute !important; 
    will-change: transform !important;
    left: -260px !important;
    max-width: 200px !important;
}
</style>