import httpClient from './httpClient';

const END_POINT = '/users';


const getUsers = () => httpClient.get(END_POINT);
const getAllUsers = () => httpClient.get(END_POINT + '/all');
const getUser = (id) => httpClient.get(END_POINT + '/' + id);
const createUser = (user) => httpClient.post(END_POINT, { user });
const updateUser = (id, user) => httpClient.put(END_POINT + '/' + id, { user } );
const activateUser = (id) => httpClient.put(END_POINT + '/activate/' + id );
const deleteUser = (id) => httpClient.delete(END_POINT + '/' + id);
const getTimesheetInvoiceMapping = (id) => httpClient.get(END_POINT + '/' + id + '/timesheet-invoice-mapping')

const getMe = () => httpClient.get(END_POINT + '/me');
const updateMe = (user) => httpClient.put(END_POINT + '/me', { user } );


export {
    getUsers,
    getAllUsers,
    getUser,
    createUser,
    updateUser,
    deleteUser,
    getTimesheetInvoiceMapping,
    getMe,
    updateMe,
    activateUser
}

