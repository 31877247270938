<template>

<b-form-row>
    <b-col class="col-sm-3"><label class="col-form-label">{{ label }}</label></b-col>
    <b-col class="col-sm-6">
        <component :is="_type"
            size="sm"
            v-bind="$attrs"
            v-on="$listeners"
            :type="type"
            :value="value"
            :state="(errors === undefined) ? null : false"
        />
        <span v-if="errors" class="error">{{ errors[0] }}</span>
    </b-col>
    <b-icon :icon="iconField" scale="1" variant="primary" class="mt-2" v-if="iconField" @click="emitEvent">
    </b-icon>
    <b-button-group v-b-tooltip.hover.right :title="helpText" v-if="helpText != ''">
        <b-icon :icon="iconName" scale="1" variant="primary" class="mt-2"></b-icon>
    </b-button-group>
</b-form-row>

</template>

<script>
export default {
    props: {
        '_type': {
            type: String,
            default: 'b-form-input'
        },
        type: String,
        value: [String, Object, Number, File, Boolean, Date],
        label: String,
        errors: Array,
        'helpText': {
            type: [String, Object, Number, File, Boolean, Date],
            default: ''
        },
        'iconName': {
            type: String,
            default: 'question-circle'
        },
        'iconField': {
            type: String,
            default: ''
        }
    },
    methods: {
        emitEvent(){
            this.$root.$emit(`edit${this.label}`)
        }
    }
}
</script>

<style>
.tooltip-inner {
    text-align: left;
}
</style>